.progress-status-text {
  color: black;
  margin-left: -5rem !important;
  font-family: "Inter", sans-serif;
  font-size: 0.8rem;
  opacity: 0.5;
}
.progress-time-text {
  color: black;
  margin-left: -5rem !important;
  font-family: "Inter", sans-serif;
  font-size: 0.9rem;
}
@media screen and (max-width: 800px) {
  .advance-search-filter-button-div {
    display: block !important;
    padding-bottom: 10px;
  }
  .advance-search-filter-button {
    width: 60px !important;
  }
  .advance-search-reset-button {
    width: 60px !important;
  }
  .progress-status-text {
    color: black;
    margin-left: -5rem !important;
    font-family: "Inter", sans-serif;
    font-size: 0.56rem;
    opacity: 0.5;
  }
  .progress-time-text {
    color: black;
    margin-left: -5rem !important;
    font-family: "Inter", sans-serif;
    font-size: 0.9rem;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.advance-search-container {
  background-color: #dde1e9;
  min-width: -webkit-fill-available;
  height: auto;
  text-align: center;
}
.advance-search-input {
  height: 32px;
  border: 1px solid #a8bed0;
  margin: 10px 20px;
  color: gray;
  border-radius: 3px;
  padding-left: 15px;
  width: 150px !important;
  line-height: 30px;
  font-size: 13px;
}

.advance-search-input::placeholder {
  padding: 15px;
  color: #797575;
}
.advance-search-filter-button {
  background-color: #8c8c9e;
  color: white;
  height: 30px;
  width: 70px;
  min-width: fit-content;
  border: 0 solid gray;
  border-radius: 3px;
  margin-top: 10px;
}

.advance-search-mobile-input {
  height: 32px;
  border: 1px solid #a8bed0;
  margin: 10px 20px;
  color: gray;
  border-radius: 3px;
  padding-left: 15px;
  width: 150px !important;
  line-height: 30px;
  font-size: 13px;
  display: none;
}
.advance-search-filter-button-div {
  display: inline-block;
}
.advance-search-reset-button {
  background-color: #8c8c9e;
  color: white;
  height: 30px;
  width: 70px;
  border: 0 solid gray;
  border-radius: 3px;
  margin-top: 10px;
  margin-left: 20px;
}

.ag-floating-filter-button {
  display: none !important;
}

.progress-item {
  height: 2rem !important;
  width: 2rem !important;
  background-color: white !important;
}
.progress-active-step {
  background-color: #0e51cb !important;
  border-style: solid;
  border-width: 5px;
  border-color: #0e51cb !important;

}
.progress-passed-step {
  background-color: white !important;
  border-style: solid;
  border-width: 5px;
  border-color: #0e51cb !important;

}
.progress-next-step {
  background-color: white !important;
  border-style: solid;
  border-width: 5px;
  border-color: gray !important;
}
.progress-active-step-icon {
  color: white;
}
.progress-passed-step-icon {
  color: #0e51cb;
}
.progress-next-step-icon {
  color: gray;
}
.progress-item:hover:before {
  content: attr(data-tooltip);
  position: absolute;
  padding: 5px 10px;
  margin: -60px 0 0 0;
  left: -25px;
  background: #0e51cb;
  color: white;
  border-radius: 3px;
}
.progress-item:hover:after {
  content: '';
  position: absolute;
  margin: -90px 0 0 0;
  right: 10px;
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
  border-top: 10px solid #0e51cb;
  border-left: 5px solid transparent;
}
.progress-line {
  background-color: white !important;
  height: 11px !important;
  padding-top: 8px !important;
}
.progress-active-line {
  background-color: #0e51cb !important;
}
.progress-passive-line {
  background-color: gray !important;
  opacity: 0.5;
}
.toast-message-box {
  position: absolute;
  left: 40%;
  top:-9%;
  z-index: 1;
}